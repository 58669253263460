<header class="primary-header">
  <div class="logo-container">
    <a routerLink="/login">
      <img src="/assets/images/delm8-logo.png" alt="logo" />
    </a>
  </div>
  <div class="header-right">
    <ul class="menu-items">
      <li>
        <a href="https://delm8.com/" target="_blank">Home</a>
      </li>
      <li>
        <a href="https://delm8.com/help-center/" target="_blank">FAQs</a>
      </li>
      <li>
        <a href="https://delm8.com/blog/" target="_blank">Blog</a>
      </li>
      <li>
        <a href="https://delm8.com/contact-us/" target="_blank">Contact Us</a>
      </li>
      <li>
        <a><mat-icon> call</mat-icon> 020 7070 0998</a>
      </li>
    </ul>
    <ul class="social-icons">
      <li>
        <a href="https://www.facebook.com/delm8" target="_blank"
          ><img src="/assets/images/icons/ic_round-facebook.svg" alt="facebook"
        /></a>
      </li>
      <li>
        <a href="https://www.instagram.com/delm8_mobile_app/" target="_blank"
          ><img
            src="/assets/images/icons/ri_instagram-fill.svg"
            alt="instagram"
        /></a>
      </li>
      <li>
        <a href="https://www.youtube.com/watch?v=aBS8he0KIDw&t=11s" target="_blank"
          ><img src="/assets/images/icons/mdi_youtube.svg" alt="logo"
        /></a>
      </li>
      <li>
        <a href="https://twitter.com/delm83" target="_blank"
          ><img src="/assets/images/icons/mage_x.svg" alt="twitter"
        /></a>
      </li>
    </ul>
  </div>
</header>
